var $ = require("jquery");
import Swiper from 'swiper/dist/js/swiper.js';

// Number clamping function
Number.prototype.clamp = function(min, max) {
	return Math.min(Math.max(this, min), max);
};

// Story Nav Sticky Scroll

if($('.story-sidebar').length > 0) {

	var $storyNav = $('.story-nav');
	var $storyInfoPanel = $('.story-info-panel');
	var $navBar = $('.main-navbar');

	// Desktop

	function topSpacingSize() {
		if(window.innerWidth < 850)
			return 0;
		else if(window.innerWidth < 1150)
			return 100;
		else {
			return 120;
		}
	}

	var chapterSidebar = new StickySidebar('.story-sidebar', {
		topSpacing: topSpacingSize(),
		bottomSpacing: 0,
	    containerSelector: '.story-main',
	    innerWrapperSelector: '.story-sidebar__inner',
	    minWidth: 1000
	});

	function storyScroll() {

		var scrollY = window.scrollY || window.pageYOffset;
		var screenMid = scrollY + (window.innerHeight / 2);
		var screenBottom = scrollY + window.innerHeight;

		chapterSidebar.options.topSpacing = topSpacingSize();

		// Mobile Nav Scroll
		if(window.innerWidth < 1000) {
			var fixPoint = $storyInfoPanel.offset().top + $storyInfoPanel.outerHeight() - $navBar.outerHeight();
			var navShift = 0;
			// if(scrollY > fixPoint) {
			// 	navShift = scrollY - fixPoint;
			// }
			// $storyNav.css({
			// 	'-webkit-transform': 'translateY('+navShift+'px)',
			// 	'-moz-transform': 'translateY('+navShift+'px)',
			// 	'transform': 'translateY('+navShift+'px)'
			// });
			if(scrollY > fixPoint) {
				$storyNav.css({ 'position': 'fixed', 'top': 0 });
				$storyInfoPanel.css({ 'margin-bottom': $storyNav.outerHeight() });
			}
			else {
				$storyNav.css({ 'position': 'relative', 'top': 0 });
				$storyInfoPanel.css({ 'margin-bottom': 0 });
			}
		}
		else {
			// $storyNav.css({
			// 	'-webkit-transform': 'translateY(0px)',
			// 	'-moz-transform': 'translateY(0px)',
			// 	'transform': 'translateY(0px)'
			// });
			$storyNav.css({ 'position': 'relative', 'top': 0 });
			$storyInfoPanel.css({ 'margin-bottom': 0 });
		}

		// Check which is current Article
		var navLinkActive = false;
		$('.chapter-article').each(function() {
			var $this = $(this);
			var $thisLink = $('.story-nav__link[data-id='+$this.data('id')+']');
			if(screenMid > $this.offset().top && screenMid < $this.offset().top + $this.outerHeight()) {
				$this.addClass('chapter-article_current');//.siblings().removeClass('chapter-article_current');
				$thisLink.addClass('story-nav__link_active').siblings().removeClass('story-nav__link_active');
				navLinkActive = true;

		        // Update our URL
		        if ($this.data('id')) {
		          // Get our current URL
		          var current = window.location.href;

		          // Split the current URL by slash to test
		          var split = current.split('/');

		          // Check that we're on a new page
		          if (split[split.length-1] != $this.data('id')) {
					// Format our new URL
		            var new_url   = current.substring(0, (0 - split[split.length-1].length)) + '' + $this.data('id');
		            var new_title = $this.find('.chapter-article__title').text();

		            // window.history.pushState('data', new_title, new_url);
		            // document.title = new_title;
		          }
		        }
			}
			else {
				$this.removeClass('chapter-article_current');
				$thisLink.removeClass('story-nav__link_active');
			}

			if( $this.hasClass('chapter-article_current') ) {
				$this.trigger('focus');
			}

		});
		// Fallback to first one
		if(!navLinkActive) {
			$('.story-nav__link[data-id='+$('.chapter-article').first().data('id')+']').addClass('story-nav__link_active').siblings().removeClass('story-nav__link_active');
		}

		// Story Progress
		var $chapter = $('.chapter-article_current');
		if($chapter.length > 0) {
			// Current Positions and Dims
			var chapterStart = $chapter.offset().top;
			var chapterEnd = chapterStart + $chapter.outerHeight();
			// Progress
			var progress = ((screenBottom - chapterStart) / (chapterEnd - chapterStart)).clamp(0, 1);
			$('.story-nav__progress').css({
				'-webkit-transform': 'scaleX('+progress+')',
				'-moz-transform': 'scaleX('+progress+')',
				'transform': 'scaleX('+progress+')'
			});
		}

		// Go to next story at the end
		if(scrollY + window.innerHeight + 10 >= $(document).height()) {
			$('.story-nav-next:visible a').click();
		}
		//chapterSidebar.updateSticky();
	}
	$(document).scroll(function() { storyScroll(); });
	$(window).resize(function() { storyScroll(); });
	storyScroll();

}

// Story Lazy Load

$('.story-main').on('click', '[data-chapter-ajax]', function(e) {
	if($('.story-main').hasClass('loading')) return;
	$('.story-main').addClass('loading');
	e.preventDefault();
	const url = $(this).attr('href');
	const direction = $(this).data('direction');
	$.get(url, function(data) {
		const $current = $('.chapter-article_current');
		const $data = $(data);

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'pageview',
			'pagePath': url,
			'pageTitle': document.title
		});

		// Create new chapter
		const id = $data.find('.chapter-article_current').data('id');
		const $content = $data.find('.chapter-article_current .chapter-article__inner');
		const $new = $('<article class="chapter-article ajaxed" data-id="'+id+'">');
		$new.append($('<div class="chapter-article__inner">'));
		$new.children('.chapter-article__inner').html($content.html()).attr('class', $content.attr('class'));

		$new.find('img').on('load', function() {
			$(window).trigger('resize');

			if(typeof(Event) === 'function') {
				var event = new Event('resize');
			}else{
				var event = document.createEvent('Event');
				event.initEvent('resize', true, true);
			}
			window.dispatchEvent(event);

			//window.dispatchEvent(new Event('resize'));
		});

		// Add It In
		if(direction == 'previous') {
			// Remember scroll point of current chapter
			var $lastTop = $('.story-nav-previous+.chapter-article');
			var lastTopInitialY = $lastTop.offset().top;
			// Insert
			$new.insertAfter($('.story-nav-previous'));
			// Update previous link
			const $prev = $data.find('.story-nav-previous');
			if($prev.length > 0) {
				$('.story-nav-previous__inner').attr('href', $prev.find('.story-nav-previous__inner').attr('href'));
				//$('.story-nav-previous__inner').data('chapter-ajax', data.previous.api_url);
				$('.story-nav-previous__title').html($prev.find('.story-nav-previous__title').html());
			}
			else {
				$('.story-nav-previous').hide();
			}
			// Scroll back to where we were
			var newTopInitialY = $lastTop.offset().top;
			var shiftBy = newTopInitialY - lastTopInitialY;
			$('.story-main').css({ 'margin-top': (-shiftBy)+'px' }).animate({ 'margin-top': 0 }, 500, function() {
				$(window).trigger('resize');
				$(document).scroll();
				setTimeout(function() { chapterSidebar.updateSticky(); }, 1);
			});
		}
		else {
			$new.insertBefore($('.story-nav-next'));
			// Update next link
			var $next = $data.find('.story-nav-next');
			if($next.length > 0) {
				$('.story-nav-next__inner').attr('href', $next.find('.story-nav-next__inner').attr('href'));
				//$('.story-nav-next__inner').data('chapter-ajax', data.next.api_url)
				$('.story-nav-next__title').html($next.find('.story-nav-next__title').html());
			}
			else {
				$('.story-nav-next').hide();
			}
			chapterSidebar.updateSticky();
			$('html, body').animate({ 'scrollTop': $new.offset().top - $('.main-navbar').outerHeight() }, 1000, function() {
				$(window).trigger('resize');
				$(document).scroll();
				setTimeout(function() { chapterSidebar.updateSticky(); }, 1);
			});

		}

		$('.story-main').removeClass('loading');

		if(typeof(Event) === 'function') {
			var event = new Event('resize');
		}else{
			var event = document.createEvent('Event');
			event.initEvent('resize', true, true);
		}
		window.dispatchEvent(event);

	});
});
// Initial Image Load Refresh Stuff
$('.story-main').find('img').on('load', function() {
	$(window).trigger('resize');
	window.dispatchEvent(new Event('resize'));
});

// Scroll to link if already open

$('.story-nav__link').click(function(e) {
	var $this = $(this);
	var chapterID = $this.data('id');
	// Is already open
	if($('.chapter-article[data-id='+chapterID+']').length > 0) {
		e.preventDefault();
		$("html, body").animate({ scrollTop: $('.chapter-article[data-id='+chapterID+']').offset().top }, 500);
		$('.chapter-article[data-id='+chapterID+']').trigger('focus');
	}
	else {
		return true;
	}
})

// Story Mobile Nav Expand

$('.story-nav').on('click', '.story-nav__link_active', function(e) {
	e.preventDefault();
	$('.story-nav').toggleClass('open');
});

$('body').on('click keypress', '.item-actions__link_share--container, .share-channels__channel_close', function(e) {
	var keycode = e.key || e.which;
	if( e.type = 'keypress' && keycode == 'Enter' || e.type == 'click' ) {
		e.preventDefault();
		$('.item-actions__link_share--container').toggleClass('open');
	}
});

$('body').on('click keypress', '.story-info-panel__action_share', function(e) {
	var keycode = e.key || e.which;
	if( e.type = 'keypress' && keycode == 'Enter' || e.type == 'click' ) {
		$('.item-actions__link_share--container').toggleClass('open');
    }
});

$('body').on('click keypress', '.js-item-actions__link_back-to-top', function(e) {
	var keycode = e.key || e.which;
	if( e.type = 'keypress' && keycode == 'Enter' || e.type == 'click' ) {
		window.scrollTo({top: 0, behavior: 'smooth'});
    }
});

// Horizontal Sliders
$('.related-stories-slide-block__inner').each(function() {
	const $sliderContainer = $(this);
	const slider = new Swiper($sliderContainer, {
		setWrapperSize: true, // Needed for IE11 :-()
		slidesPerView: 'auto',
		wrapperClass: 'related-stories-slide-block__list',
		slideClass: 'related-stories-slide-block__list-item',
		navigation: {
			nextEl: $('.related-stories-slide-block .next'),
			prevEl: $('.related-stories-slide-block .prev'),
		}
	});
});
