
var $ = require("jquery");

// Show add to board modal
$('body').on('click', '.add-to-board-link', e => {
	e.preventDefault();
	const url = $(e.currentTarget).attr('href');
	const $modal = $('.modal_add-to-board').addClass('loading active').trigger('focus');
	$('html').addClass('show-modal');
	// Load Modal Content
	$('.modal_add-to-board .modal__content').load(url+' .add-to-board', (responseText) => {
		$modal.removeClass('loading');
	});
});

// Show Add Board Field

$('body').on('click', '.js_show-add-board', () => {
	$('.add-to-board__new').addClass('show');
	$('.add-to-board__new input[type=text]').focus();
});

// Add Board Clicked

$('body').on('click', '.add-to-board__new button', e => {
	e.preventDefault();
	const boardName = $('.add-to-board__new input[type="text"]').val(),
		addUrl = $('.add-to-board__new input[type="text"]').data('url');
	if(boardName == '') return;
	// Disable Further Input
	$('.add-to-board__new input, .add-to-board__new button').attr('disabled', true);
	// Create New Board
	$.post({
		url: addUrl,
		data: {
			'board_name': boardName
		},
		dataType: 'json',
		success: result => {
			// Insert New Button
			const newBoardId = result.board_id,
				addUrl = result.add_url;
			const $newBoard = $(`
				<li data-boardid="${newBoardId}">
					<input name="board" id="add-to-board_${newBoardId}" value="${newBoardId}"
						data-url="${addUrl}" type="checkbox">
					<label for="add-to-board_${newBoardId}">${boardName} <span class="check"></span></label>
				</li>`);
			$('.add-to-board__new').after($newBoard);
			// Click new button
			$('#add-to-board_'+newBoardId).prop('checked', true).change();
		
			// Clear and rehide New Form
			$('.add-to-board__new').removeClass('show');
			$('.add-to-board__new input, .add-to-board__new button').attr('disabled', false);
			$('.add-to-board__new input').val('');
			// Update board count in Nav
			var $boardCount = $('.main-login-area__user-link_icon_boards sup');
			$boardCount.html(result.board_count);
			$boardCount.addClass('newboard');
			//setTimeout(function() { $boardCount.removeClass('new'); }, 3000);
		}
	});
	
});

// Add To Board Form

$('body').on('change', '.add-to-board__boards input[name=board]', e => {
	const $this = $(e.currentTarget);
	const boardID = $this.val(),
		added = $this.is(':checked'),
		type = $('.js-add-to-board-form input[name=type]').val(),
		item_id = $('.js-add-to-board-form input[name=item_id]').val();
		var url = $this.data('url');
		var data = {
			'board_id': boardID,
			'item_id': item_id,
			'type': type,
			'added': added
		}
		
		if( added !== true) {
			data._method = 'DELETE';
			url = $this.data('delete-url')
		}

		$.post({
			url: url,
			data: data,
			success: function(result ) {
				addToBoardPopupStatus(added ? 'Added to board' : 'Removed from board');
			}
		});
	
});

// Board Actions
// =============

// Edit Title and Description inline
$('body').on('keyup', '.user-board__header [contenteditable]', e => {
	const $this = $(e.currentTarget),
		keyPressed = e.originalEvent.keyCode;
	// Pressed Enter - "Submit"
	if(keyPressed == 13) {
		$this.blur();
	}
}).on('blur', '.user-board__header [contenteditable]', e => {
	const $this = $(e.currentTarget);
	// Remove HTML, esp newlines
	$this.html($this.text());
	// Save it
	const boardTitle = $('.bar-header__title').text(),
		boardDescription = $('.bar-header__description').text();
	$.post({
		url: window.location,
		dataType: 'json',
		data: {
			_method: 'PUT',
			title: boardTitle,
			description: boardDescription
		},
		success: result => {
			if(!result.success) alert(result.error);
		}
	});
});

// Show actions popup
$('body').on('click', '.board-actions-button', e => {
	const $this = $(e.currentTarget).children('.board-actions');
	$this.find('.board-actions__action_share').removeClass('open'); // Rehide social
	$this.toggleClass('show');
	// Scroll down?
	if($this.hasClass('show')) {
		var screenBottom = window.scrollY + window.innerHeight;
		var thisBottom = $this.offset().top + $this.outerHeight();
		if(screenBottom < thisBottom) {
			$("html, body").animate({ scrollTop: '+='+(thisBottom - screenBottom + 20) }, 300);
		}
	}
});

// Show social links
$('body').on('click', '.board-actions__action_share', e => {
	e.stopPropagation();
	$(e.currentTarget).toggleClass('open');
});

// Copy To Clipboard

$('body').on('click', '.board-actions__copy-to-clipboard', function(e) {
	e.stopPropagation();
	const $this = $(this);
	const $notice = $this.siblings('.board-actions__copied-to-clipboard');
	const $dummy = $notice.children('.board-actions__copied-dummy');
	$notice
		.css({ 'display': '-webkit-box', 'display': '-ms-flexbox', 'display': 'flex' }).hide()
		.fadeIn(200).delay(3000).fadeOut(200);
	$dummy[0].select();
	document.execCommand('copy');
	$dummy[0].blur();
});

// Delete Board
$('body').on('click', '.board-actions__action_delete', e => {
	e.preventDefault();
	e.stopPropagation();
	if(confirm('Are you sure you want to delete this board?')) {
		const $this = $(e.currentTarget);
		const url = $this.attr('href');
		const $box = $this.closest('.block');
		$.post({
			url: url,
			data: { _method: 'DELETE' },
			dataType: 'json',
			success: result => {
				if(!result.success){
					alert(result.error);
				}  else {
					// Remove Box?
					$box.fadeOut(200, e => {
						$box.remove();
					});
					// Redirect?
					if ($this.data('redirect-url') || '' == '') {
						window.location = $this.data('redirect-url');
					}
				}

			}
		});
		

	}
});

// Public Private

$('body').on('click', '.board-actions_privacy', e => {
	e.preventDefault();
	e.stopPropagation();
	const $this = $(e.currentTarget);
	const $box = $this.closest('.board-actions');
	const is_public = !$this.hasClass('private');
	if(is_public) $box.addClass('public'); else $box.removeClass('public');
	$.post({
		url: $this.attr('href'),
		data: {
			_method: 'PUT',
			is_public: is_public
		},
		success: result => {
			
		}
	});
});

function addToBoardPopupStatus(status) {
	$('.add-to-board__status').clearQueue()
		.animate({ 'opacity': 0 }, 200, function() { $(this).html(status); })
		.animate({ 'opacity': 1 }, 200)
		.delay(3000)
		.animate({ 'opacity': 0 }, 200);
}

// Remove item from board

$('body').on('click', '.board-actions-button_remove', function(e) {
	e.preventDefault();
	var $this = $(this);
	var boardID = $this.data('board-id'),
		type = $this.data('type'),
		itemID = $this.data('item-id'),
		url = $this.data('delete-url');
	$.post({
		url: url,
		data: {
			'_method': 'PUT',
			'board_id': boardID,
			'item_id': itemID,
			'type': type,
			'added': false
		},
		success: result => {
			// We all good
		}
	});
	// Remove box
	var $box = $this.closest('.block');
	$box.fadeOut(200, e => {
		$box.remove();
	});
});

// New Board on Boards Page

$('body').on('click', '.block_type_board-add a', function(e) {
	e.preventDefault();
	var $block = $(this).closest('.block_type_board-add');
	$block.addClass('show-form');
	$block.find('textarea').focus();
});

$('body').on('submit', '.block_type_board-add form', function (e) {
	e.preventDefault();
	var $this = $(this);
	var boardName = $this.children('textarea[name="board_name"]').val();
	if(boardName != '') {
		$this.find('button').prop('disabled', true);
		$.post({
			url: $this.data('url'),
			data: {
				board_name: boardName,
				public: ($this.closest('.boards-list').hasClass('public') ? 'true' : 'false')
			},
			dataType: 'json',
			success: result => {
				window.location.href = result.board_url;
			}
		});
	}
});