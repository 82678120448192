import "babel-polyfill";

var $ = require("jquery");
import Vue from 'vue';

import InstantSearch from 'vue-instantsearch';

Vue.component('filter-bar', require('./components/filterBarComponent.vue').default);
Vue.component('refinement-list-grpl', require('./components/refinement-list-override.vue').default);
Vue.component('ml-current-refined', require('./components/ml-current-refined.vue').default);

Vue.use(InstantSearch);

if( $('#app').length > 0 ){
	const app = new Vue({
		el: '#app',
		data: function() {
			return {
				page: 1
			}
		},
		props: {
			isLoading: false,
		},
		mounted: function() {
			$('.ais-powered-by a').attr('target', '_blank');
			this.$nextTick(function () {
				if(window.innerWidth > 1000) {
					$('.explorer').addClass('explorer_show-nav');
				}
			});
			window.explorerStickyScroll();
		},
		methods: {
			loadMore: function() {
				const $resultsBlock = $('.ais-results');
				if($resultsBlock.length > 0) {
					const bottomY = $resultsBlock.offset().top + $resultsBlock.outerHeight() - window.pageYOffset - window.innerHeight;
					if(bottomY < 0 && !this.isLoading) {
						this.isLoading = true;
						this.page++;
						setTimeout(() => { this.isLoading = false}, 1000);
					}
				}
			}
		},
	});
}

// Imports

import Explore from './components/explore';
import './components/home';
import './components/twitter';
import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar.min.js';

import './components/story';
import './components/boards';

// Global ajax setting to handle CSRF stuff

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(document).ready(function () {

	$('.ais-index .main-search-bar__button').click(function (e) {
		e.preventDefault();
		var exp = new Explore();
		var term = $('.main-search-bar__input').val();
		if (term.length >= 3) {
			exp.simpleExplore('.explorer__main', term);
		}
		return false;
	});
});

var __simpleExplore = function (searchterm) {
	$.get('/async/explore/' + searchterm, function (response) {
		$('#story_list').html(response);
	});
}


// Burger Nav

$('.main-nav-toggle, .main-navbar__close').click(function () { $('html').toggleClass('show-nav'); }).click();


// Universal Scroll to page anchor function

$('[data-scrollto]').click(function () {
	var $target = $($(this).data('scrollto'));
	if ($target.length > 0) {
		$("html, body").animate({ scrollTop: $target.offset().top }, 500);
	}
	return false;
});


// General Modal Close

$('.modal__close, .modal__bg').click(function (e) {
	e.preventDefault();
	$(this).closest('.modal').removeClass('active');
	if ($('.modal.active').length == 0) {
		$('html').removeClass('show-modal');
	}
});


// Share Links
$('body').on('click', '.share-link', function (e) {
	var $this = $(this);
	var shareTo = $this.data('share-to');
	var shareUrl = $this.data('share-url') || encodeURI(window.location);
	var shareText = $this.data('share-text') || '';
	var pageTitle = encodeURI(document.title);
	var url;

	if (shareTo == 'email') {
		url = 'mailto:?subject=' + pageTitle + '&body=' + shareUrl;
		window.location = url;
		return false;
	}
	else if (shareTo == 'twitter')
		url = 'https://twitter.com/intent/tweet?url=' + shareUrl + '&text=' + shareText;
	else if (shareTo == 'facebook')
		url = 'https://www.facebook.com/sharer.php?u=' + shareUrl;
	else if (shareTo == 'linkedin')
		url = 'https://www.linkedin.com/shareArticle?url=' + shareUrl + '&title=' + pageTitle;
	else if (shareTo == 'whatsapp')
		url = 'whatsapp://send?text=' + shareUrl;

	window.open(url, 'Share', 'width=500,height=400,resizable=yes').focus();
	return false;
});


// Automatically update the search term in the URL.
// TODO: Move to filterBarComponent.vue and incorporate filters

$('#explore-search-form input[type="search"]').on( 'keydown', function (e) {
	$('.ais-results-block').addClass('searching');
});

$('#explore-search-form input[type="search"]').on( 'keyup', function (e) {
	var new_url = "/explore/stories/?search=" + encodeURIComponent($.trim($(this).val())).replace(/%20/g, '+');
	setTimeout(function() {
		$('.ais-results-block').removeClass('searching');
	}, 1000);

	if (typeof(window.history.pushState) == 'function') {
	    window.history.replaceState(null, null, new_url);
	}
});

$('.js--swf-full').click(function (e) {
	$('.resource-page__swf').addClass('resource-page__swf--active');
});

$(document).on('click', '.resource-page__swf--active', function (e) {
  $(this).removeClass('resource-page__swf--active');
});

//Accesibility Updates
$('#explore-search-form button').attr('title', 'Search');
$('#explore-search-form .ais-input').attr('aria-label', 'Search for...');

$('.ais-pagination__item').hover(function(){
	$('.ais-pagination__link').each(function(){
		if( $(this).parent().hasClass('ais-pagination__item--first') ) {
			$(this).attr('title', 'Go To First Page')
		} else if( $(this).parent().hasClass('ais-pagination__item--previous') ) {
			$(this).attr('title', 'Go To Previous Page')
		} else if( $(this).parent().hasClass('ais-pagination__item--last') ) {
			$(this).attr('title', 'Go To Last Page')
		} else if( $(this).parent().hasClass('ais-pagination__item--next') ) {
			$(this).attr('title', 'Go To Next Page')
		} else {
			$(this).attr('title', 'Go To Page ' + $.trim($(this).html()))
		}
	});
});