
var $ = require("jquery");
//import Swiper from 'swiper'; // Causes errors in IE11
import Swiper from 'swiper/dist/js/swiper.js';

// Main Slider
$('.home-featured').each(function() {
	const $sliderContainer = $(this);
	const slider = new Swiper($sliderContainer, {
		slidesPerView: '1',
		setWrapperSize: true,
		threshold: 20,
		loop: true,
		autoplay: {
			delay: 5000,
		},
		wrapperClass: 'home-featured__inner',
		slideClass: 'home-featured__slide',
		navigation: {
			nextEl: $sliderContainer.find('.prev'),
			prevEl: $sliderContainer.find('.next'),
		}
	});
});

// KS/Subject/Theme Box
// ====================


// Change Tab

$('body').on('click', '.home-cat-browser__tab', function(e) {
	e.preventDefault();
	if($(this).hasClass('on')) return;
	$(this).addClass('on').siblings().removeClass('on');
	$('.home-cat-browser__section').removeClass('on').filter('.'+$(this).data('tab')).addClass('on');
});

// View All
$('body').on('click', '.home-cat-browser__view-all', function(e) {
	e.preventDefault();
	var dataUrl = $(this).data('popup-data');
	var title = $(this).data('popup-title');
	var $list = $('.modal_home-categories-all .category-listing__list');
	$list.html('');
	$('.modal_home-categories-all').addClass('loading');
	$.getJSON(dataUrl, function(data) {
		data.forEach(function(el) {
			$list.append('<li class="category-listing__list-item"><a href="'+el.route+'" class="category-listing__link"><span>'+el.label+'</span><sup>'+el.active_stories);
		});
		$('.modal_home-categories-all').removeClass('loading');
	});
	$('.modal_home-categories-all .category-listing__title').remove();
	$('.modal_home-categories-all .modal__content').prepend('<h2 class="category-listing__title">' + title + '</h2>');
	$('.modal_home-categories-all').addClass('active').trigger('focus');
	$('html').addClass('show-modal');
});

// Horizontal Sliders
$('.home-slide-block__inner').each(function() {
	const $sliderContainer = $(this);
	const slider = new Swiper($sliderContainer, {
		setWrapperSize: true, // Needed for IE11 :-()
		slidesPerView: 'auto',
		wrapperClass: 'home-slide-block__list',
		slideClass: 'home-slide-block__list-item',
		navigation: {
			nextEl: $sliderContainer.find('.next'),
			prevEl: $sliderContainer.find('.prev'),
		}
	});
});
