
var $ = require("jquery");

class Explore {

	constructor() {
	}

	simpleExplore(target, searchterm) {
		$.get('/async/explore/' + searchterm, function (response) {
			$(target).empty().append(response);
			$('.main-search-bar').addClass('searched');
			var stateObj = {};
			history.pushState(stateObj, 'Search Results: ' + searchterm, '/explore/stories?term=' + searchterm);
		});
	}

}
export default Explore;

import StickySidebar from 'sticky-sidebar/dist/sticky-sidebar.min.js';

window.explorerStickyScroll = function() {
	if($('.explore-filters').length) {
		var explorerSidebar = new StickySidebar('.explore-filters__scroll', {
			topSpacing: 0,
			bottomSpacing: 0,
			containerSelector: '.explore-filters',
			innerWrapperSelector: '.explore-filters__inner',
			minWidth: 720
		});
		setInterval(function() {
			// Think Vue's confusing if a bit, so let's do a semi-regular size check
			explorerSidebar.updateSticky();
		},100);
	}
}

$('.main-content').on('click','.explore-filter-bar__toggle',function() {
	$('.explorer').toggleClass('explorer_show-nav');
});

// Expand Filters
$('body').on('click', '.explore-filters__expand', function(e) {
	e.preventDefault();
	$(this).siblings('.explore-filters__filters').toggleClass('cropped');
	if( $(this).siblings('.explore-filters__filters').hasClass('cropped') ) {
		$(this).attr('title', 'Show more');
	} else {
		$(this).attr('title', 'Show less');
	}
});

// Move to results on resource type click
$('body').on('click', '.explore-type-filter__link', function() {
	var slug = $(this).data('slug');
	var input = $('.ais-input').val();
	$("html, body").animate({scrollTop: $('.ais-results-block').offset().top - $('.main-navbar').outerHeight() }, 500);
	var stateObj = { slug : slug };
	if(input.length > 0){
		history.pushState(stateObj, "Explore", "/explore/" + slug + "?search="+input );
	} else {
		history.pushState(stateObj, "Explore", "/explore/" + slug);
	}
	
});

// Move to top on pagination click

$('body').on('click', '.ais-pagination__item:not(.ais-pagination__item--active)', function() {
	$("html, body").animate({ scrollTop: $('.ais-results-block').offset().top - $('.main-navbar').outerHeight() }, 500);
});

// Mobile Bits
// ===========

$('.explore-type-filter__link_active').click(function(e) {
	e.preventDefault();
	$('html').toggleClass('explore-types-nav-toggle');
});
