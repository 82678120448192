<template>
	
	<div class="explore-filter-bar__selected explore-selected">
		<template v-for="(filter, index) in currentRefinedValues">
			<button class="explore-selected__tag" :key="index" v-on:click.prevent="removeFilter(filter)" :title="'Remove ' + filter.name + ' filter'">
				<span>{{ filter.name }}</span>
				<span class="explore-selected__remove">Remove</span>
			</button>
		</template>
	</div>

</template>

<script>

import { Component } from 'vue-instantsearch';
export default {
	mixins: [Component],
	computed: {
		currentRefinedValues() {
			return this.searchStore._helper.lastResults ? this.searchStore._helper.lastResults.getRefinements() : [];
		},
		show() {
			return true;
		}
	},
	methods: {
		removeFilter: function(filter) {
			this.searchStore._helper.removeFacetRefinement(filter.attributeName, filter.name);
		}
	},
	mounted: function() {
	},
	watch: {
    }
};

</script>