<template>
	<section class="explore-type-filter">
		<div class="explore-type-filter__inner">
			<a v-bind:key="filter.slug"
				v-bind:href="'/explore/'+ filter.slug"
				class="explore-type-filter__link active-fill"
				:data-slug="filter.slug"
				:data-label="filter.label"
				v-bind:class="[ activeIndex == filter.slug ? 'active' : '', 'resource-border_'+filter.slug ]"
				v-on:click.prevent="changeFilter"
				:title="'Search ' + filter.label"
				v-for="filter in filters">
					<div :class="['explore-type-filter__icon', 'svg-icon', 'svg-icon_'+filter.slug]"></div>
					{{ filter.label }} <sup>{{ filter.count }}</sup>
			</a>
		</div>
	</section>
</template>

<script>
	import { Component } from 'vue-instantsearch';
	export default {
		mixins: [Component],
		data: function() {
			return {
				filters: window.filters
			}
		},
		computed: {
			query() {
				return this.searchStore;
			},
			activeIndex() {
				return this.searchStore._helper.getIndex();
			}
		},
		mounted: function() {
			filters.forEach((val, index, arr) => {
				const derivedHelper = this.searchStore._helper.derive(searchParameters => {
					const params = searchParameters.setIndex(val.slug);
					return params;
				});
				derivedHelper.on('result', (content, state) => {
					val.count = content.nbHits;
				});
			});
		},
		methods: {
			changeFilter: function(event) {
				// Set Index
				this.searchStore._helper.setIndex(event.target.getAttribute('data-slug'));
				// Set Count Label
				if(null!==document.querySelector('.exploreCountType')){
					document.querySelector('.exploreCountType').innerHTML = event.target.getAttribute('data-label');
				}
				return false;
			}
		}
	};
</script>