var $ = require("jquery");
if($('.home-latest-tweets').length > 0){
    $.getJSON('/api/json/twitter', function (data) {
        data.forEach(function (tweet, index) {
            var tweetHTML = '<li class="home-latest-tweets__tweet home-tweet">';
            if (tweet.img) {
                tweetHTML += '<div class="home-tweet__image">';
                tweetHTML += '<img src="' + tweet.img + '">';
                tweetHTML += '</div>';
            }
            tweetHTML += '<div class="home-tweet__text">' + tweet.text + '</div>';
            tweetHTML += '<div class="home-tweet__date">' + tweet.date + '</div>';
            tweetHTML += '</li>';
            $('.home-latest-tweets__tweets').append($(tweetHTML));
        });
        if ($('.home-latest-tweets__tweet').length > 0) {
            $('.home-latest-tweets').removeClass('home-latest-tweets_loading');
        }
    });
}